import RBILogo from '@assets/img/logo-white-min.svg'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import * as styles from './Header.module.css'

const Header: FC = () => {
	const navigate = useNavigate()

	return (
		<div className={styles.header}>
			<div className={styles.logo} onClick={() => navigate('/')}>
				<RBILogo />
				<div className={styles.logoTitle}>Pandid</div>
			</div>
			<div className={styles.content}>
				<div className={styles.text}>
					<>
						<div>
							Версия: <span className={styles.tags}>in progress</span>. Время:{' '}
							<span className={styles.tags}>in progress</span>.
						</div>
					</>
				</div>
			</div>
		</div>
	)
}
export default Header
