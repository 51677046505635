import { elementTypesApi } from '@api/api-wrapper'
import { useModalActions } from '@hooks/useModalActions'
import { Button, Form, Input } from 'antd'
import { MessageInstance } from 'antd/es/message/interface'
import Modal from 'antd/es/modal/Modal'
import { useEffect } from 'react'

type Props = {
	messageApi: MessageInstance
}

const AddElementTypeModal = (props: Props) => {
	const { messageApi } = props

	const [form] = Form.useForm()
	const [open, onOpenModal, onCloseModal] = useModalActions()

	const onFinish = async (values: { name: string }) => {
		try {
			await elementTypesApi.createElementTypeApiElementTypesPost(values)
			messageApi.success('Тип успешно создан')
			onCloseModal()
		} catch (error) {
			messageApi.error('Не удалось создать тип')
		}
	}

	useEffect(() => {
		form.resetFields()
	}, [open])

	return (
		<>
			<Button onClick={onOpenModal}>Создать тип</Button>
			<Modal open={open} onCancel={onCloseModal} footer={null} title='Создать тип' centered>
				<Form onFinish={onFinish} layout='vertical' form={form}>
					<Form.Item name='name' label='Название'>
						<Input />
					</Form.Item>
					<Form.Item noStyle>
						<Button type='primary' htmlType='submit'>
							Создать
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default AddElementTypeModal
