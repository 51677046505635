import { DeleteOutlined } from '@ant-design/icons'
import { Button, Table, type TableColumnsType } from 'antd'
import { type FC, useEffect, useState } from 'react'
import { pdfApi } from '../../lib'
import type { PdfDrawingFileDto } from '../../lib/src/generated/backend'
import EmptyData from '../emptyData/EmptyData'
import { useAppDispatch } from '@store'
import { useTypedSelector } from '@hooks/useTypedSelector'
import { fetchPdfList } from '@store'
import { useActions } from '@hooks/useActions'

const PDFTable: FC = () => {
	const dispatch = useAppDispatch()
	const pdfList = useTypedSelector(state => state.schemes.pdfList)
	const status = useTypedSelector(state => state.schemes.status)

	const { deletePdfFile } = useActions()

	useEffect(() => {
		dispatch(fetchPdfList())
	}, [])

	const onDelete = async (id: string): Promise<void> => {
		await pdfApi.deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(id)
		deletePdfFile(id)
	}

	const columns: TableColumnsType<PdfDrawingFileDto> = [
		{
			title: 'Название',
			dataIndex: 'filename',
			key: 'name',
		},
		{
			title: 'Кол-во изображений',
			dataIndex: 'image_drawing_count',
			key: 'image_drawing_count',
		},
		{
			title: 'Действия',
			key: 'actions',
			render: (_, record) => (
				<Button type='link' icon={<DeleteOutlined rev='' />} onClick={() => onDelete(record.id!)} />
			),
		},
	]

	return (
		<Table
			rowKey={'id'}
			columns={columns}
			dataSource={pdfList}
			loading={status === 'loading'}
			locale={{
				emptyText: <EmptyData text='Загрузите PDF-файл' />,
			}}
		/>
	)
}

export default PDFTable
