import { ElementsApiFactory, ElementTypesApiFactory, UsersApiFactory } from '../lib/src/generated/backend'

export const loginApi = UsersApiFactory({
	isJsonMime(): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const elementTypesApi = ElementTypesApiFactory({
	isJsonMime(): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const elementApi = ElementsApiFactory({
  isJsonMime(): boolean {
    return false
  },
  basePath: window.location.origin,
})
