import { Select } from 'antd'
import React, { type FC, useEffect, useState } from 'react'
import { shapeApi } from '../../../api/api-wrapper'
import { type DiagramComponent, DiagramComponentType } from '../../../generated/backend'
import { getAppData, useAppDispatch, useAppSelector } from '../../../store'
import type { RbiDiagramComponentMapping } from '../../../store/actions/common/types'
import type { Option } from './helpers'
import { diagramComponentToOption } from './helpers'

const RBISelectors: FC = () => {
	const diagramComponents = useAppSelector(state => state.draw.diagramComponents as RbiDiagramComponentMapping)
	const { selectedShapes } = useAppSelector(state => state.draw.stage)
	const { applicationMode, image } = useAppSelector(state => state.draw)
	const [selectedLoop, setSelectedLoop] = useState<DiagramComponent | null>(null)
	const [selectedEquip, setSelectedEquip] = useState<DiagramComponent | null>(null)
	const [selectedComponent, setSelectedComponent] = useState<DiagramComponent | null>(null)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (selectedShapes.length > 0) {
			const loops = new Set<DiagramComponent>()
			const equips = new Set<DiagramComponent>()
			const components = new Set<DiagramComponent>()
			selectedShapes.forEach(shape => {
				if (shape.entities) {
					const { RBI_EQUIPMENT, RBI_CORROSION_LOOP, RBI_COMPONENT } = shape.entities as RbiDiagramComponentMapping
					if (RBI_EQUIPMENT.length === 1) {
						equips.add(RBI_EQUIPMENT[0])
					}
					if (RBI_CORROSION_LOOP.length === 1) {
						loops.add(RBI_CORROSION_LOOP[0])
					}
					if (RBI_COMPONENT.length === 1) {
						components.add(RBI_COMPONENT[0])
					}
				}
			})

			if (loops.size) {
				const loop = loops.values().next().value as DiagramComponent
				setSelectedLoop(loop)
			}
			if (equips.size) {
				const equip = equips.values().next().value as DiagramComponent
				setSelectedEquip(equip)
			}
			if (components.size) {
				const component = components.values().next().value as DiagramComponent
				setSelectedComponent(component)
			}
		}
	}, [selectedShapes])

	const onSelect = async (value: string, option: Option): Promise<void> => {
		if (applicationMode && image.id) {
			if (option.diagram_component_type === DiagramComponentType.RbiCorrosionLoop) {
				setSelectedLoop(option)
			}
			if (option.diagram_component_type === DiagramComponentType.RbiEquipment) {
				setSelectedEquip(option)
			}
			if (option.diagram_component_type === DiagramComponentType.RbiComponent) {
				setSelectedComponent(option)
			}
			for (const shape of selectedShapes) {
				if (option.diagram_component_type) {
					if (shape.entities) {
						// @ts-expect-error fix later
						if (shape?.entities[option.diagram_component_type]?.length) {
							// @ts-expect-error fix later
							for (const entity of shape.entities[option.diagram_component_type]) {
								await shapeApi.unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(
									shape.id,
									// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
									entity.id,
									option.diagram_component_type
								)
							}
						}
					}
					await shapeApi.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(
						shape.id,
						value,
						option.diagram_component_type
					)
				}
			}
			await dispatch(getAppData())
		}
	}

	if (!diagramComponents) {
		return
	}

	return (
		<>
			<Select
				style={{
					width: '320px',
				}}
				placeholder='Коррозионный контур'
				options={diagramComponents.RBI_CORROSION_LOOP.map(diagramComponentToOption)}
				value={selectedLoop ? { value: selectedLoop.id } : null}
				onSelect={onSelect}
				optionFilterProp='name'
				showSearch
			/>
			<Select
				style={{
					width: '320px',
				}}
				placeholder='Оборудование'
				options={diagramComponents.RBI_EQUIPMENT.map(diagramComponentToOption)}
				value={selectedEquip ? { value: selectedEquip.id } : null}
				onSelect={onSelect}
				optionFilterProp='name'
				showSearch
			/>
			<Select
				style={{
					width: '320px',
				}}
				placeholder='Компонент'
				options={diagramComponents.RBI_COMPONENT.map(diagramComponentToOption)}
				value={selectedComponent ? { value: selectedComponent.id } : null}
				onSelect={onSelect}
				optionFilterProp='name'
				showSearch
			/>
		</>
	)
}

export default RBISelectors
