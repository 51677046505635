import { Form, GetRef } from 'antd'
import { createContext } from 'react'

type FormInstance<T> = GetRef<typeof Form<T>>

export const EditableContext = createContext<FormInstance<any> | null>(null)

interface EditableRowProps {
	index: number
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
	const [form] = Form.useForm()

	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	)
}

export default EditableRow
