import React from 'react'
import { createRoot } from 'react-dom/client'
import './assets/css/index.css'
import App from './app'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import { store } from './store'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
	<Provider store={store}>
		<ConfigProvider
			theme={{
				components: {
					Input: {
						borderRadius: 2,
						fontSize: 14,
						colorPrimaryHover: 'rgba(33, 82, 148, 1)',
						activeShadow: 'none',
					},
					Button: {
						colorPrimaryHover: '#3965a2',
						colorPrimary: '#215294',
						colorPrimaryActive: '#0e4185',
						defaultHoverBorderColor: 'rgba(33, 82, 148, 1)',
						colorLink: '#215294',
						colorLinkHover: '#3965a2',
						colorLinkActive: '#0e4185',
					},
				},
			}}
		>
			<App />
		</ConfigProvider>
	</Provider>
)
