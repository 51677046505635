import { imageApi, pdfApi } from '@lib/index'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchPdfList = createAsyncThunk('schemes/fetchPdfList', async (_, { rejectWithValue }) => {
	try {
		const res = await pdfApi.getAllPdfDrawingFilesApiPdfDrawingFileGet()

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})

export const fetchImageList = createAsyncThunk('schemes/fetchImageList', async (_, { rejectWithValue }) => {
	try {
		const res = await imageApi.getAllImageDrawingFilesApiImageDrawingFileGet()

		return res.data
	} catch (error) {
		rejectWithValue(error)
	}
})
