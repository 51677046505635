import React, { type FC, useEffect, useState } from 'react'
import {
	getAppData,
	setContextMenuPosition,
	setLabelPosition,
	setSelectedShapes,
	useAppDispatch,
	useAppSelector,
} from '../../store'
import { Button, ConfigProvider } from 'antd'
import { TOOLS } from '../header/tools/consts'
import { ApplicationMode } from '../../../types'
import type { DiagramComponent } from '../../generated/backend'
import type {
	DemoDiagramComponentMapping,
	HazopDiagramComponentMapping,
	RbiDiagramComponentMapping,
} from '../../store/actions/common/types'
import { shapeApi } from '../../api/api-wrapper'
import HazopSelectors from './selectors/HazopSelectors'
import RBISelectors from './selectors/RBISelectors'
import DEMOSelectors from './selectors/DEMOSelectors'
import EntityLabel from './EntityLabel'
import { DeleteOutlined } from '@ant-design/icons'

const ContextMenu: FC = () => {
	const { contextMenuPosition, selectedShapes, selectedTool } = useAppSelector(state => state.draw.stage)
	const { applicationMode } = useAppSelector(state => state.draw)
	const [isEditMode, setIsEditMode] = useState(false)
	const [entities, setEntities] = useState<DiagramComponent[]>([])
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (selectedShapes.length > 0) {
			if (applicationMode === ApplicationMode.DEMO_APP) {
				const selectedSymbols = new Set<DiagramComponent>()
				let symbolCounter = 0
				for (const shape of selectedShapes) {
					const entity = shape.entities as DemoDiagramComponentMapping
					if (entity && entity.ELEMENT[0]?.id) {
						selectedSymbols.add(entity.ELEMENT[0])
						symbolCounter++
					}
				}
				if (selectedSymbols.size === 1 && symbolCounter === selectedShapes.length) {
					setIsEditMode(false)
					setEntities(Array.from(selectedSymbols))
					return
				} else {
					setIsEditMode(true)
					return
				}
			}
		} else if (applicationMode === ApplicationMode.RBI_APP) {
			const selectedLoop = new Set<DiagramComponent>()
			const selectedEquip = new Set<DiagramComponent>()
			const selectedComponent = new Set<DiagramComponent>()
			let loopCounter = 0
			let equipCounter = 0
			let componentCounter = 0

			for (const shape of selectedShapes) {
				const entities = shape.entities as RbiDiagramComponentMapping
				if (entities.RBI_COMPONENT[0].id) {
					selectedComponent.add(entities.RBI_COMPONENT[0])
					componentCounter++
				}
				if (entities.RBI_CORROSION_LOOP[0].id) {
					selectedLoop.add(entities.RBI_CORROSION_LOOP[0])
					loopCounter++
				}
				if (entities.RBI_EQUIPMENT[0].id) {
					selectedEquip.add(entities.RBI_EQUIPMENT[0])
					equipCounter++
				}
			}

			const allShapeHaveLoop = loopCounter === selectedShapes.length
			const allShapeHaveEquip = equipCounter === selectedShapes.length
			const allShapeHaveComponent = componentCounter === selectedShapes.length
			const shapesHaveEqualEntity = selectedEquip.size === 1 && selectedLoop.size === 1 && selectedComponent.size === 1

			const renderLabel = shapesHaveEqualEntity && allShapeHaveComponent && allShapeHaveEquip && allShapeHaveLoop

			if (renderLabel) {
				const entities = [...Array.from(selectedLoop), ...Array.from(selectedEquip), ...Array.from(selectedComponent)]
				setEntities(entities)
				setIsEditMode(false)
				return
			} else {
				setIsEditMode(true)
				return
			}
		} else if (applicationMode === ApplicationMode.HAZOP_APP) {
			const selectedNode = new Set<DiagramComponent>()
			const selectedEquip = new Set<DiagramComponent>()
			let equipCounter = 0
			let nodeCounter = 0
			for (const shape of selectedShapes) {
				const entities = shape.entities as HazopDiagramComponentMapping
				if (entities.HAZOP_EQUIPMENT[0].id) {
					selectedEquip.add(entities.HAZOP_EQUIPMENT[0])
					equipCounter++
				}
				if (entities.HAZOP_NODE[0].id) {
					selectedNode.add(entities.HAZOP_NODE[0])
					nodeCounter++
				}
			}

			const allShapeHaveEquip = equipCounter === selectedShapes.length
			const allShapeHaveNode = nodeCounter === selectedShapes.length
			const shapesHaveEqualEntity = selectedEquip.size === 1 && selectedNode.size === 1

			const renderLabel = allShapeHaveEquip && allShapeHaveNode && shapesHaveEqualEntity

			if (renderLabel) {
				setIsEditMode(false)
				return
			} else {
				setIsEditMode(true)
				return
			}
		}
		setIsEditMode(true)
	}, [selectedShapes])

	const onDelete = async (): Promise<void> => {
		if (selectedShapes?.length) {
			await Promise.all(
				selectedShapes.map(shape => {
					return shapeApi.deleteShapeApiShapeShapeIdDelete(shape.id)
				})
			)
			await dispatch(getAppData())
			dispatch(setSelectedShapes([]))
			dispatch(setContextMenuPosition(null))
			dispatch(setLabelPosition(null))
		}
	}

	const toggleEditMode = (): void => {
		setIsEditMode(!isEditMode)
	}

	const renderSelectors = (): React.JSX.Element | undefined => {
		if (applicationMode === 'HAZOP_APP') {
			return (
				<>
					<HazopSelectors />
					<Button type='link' icon={<DeleteOutlined />} onClick={onDelete} />
				</>
			)
		}
		if (applicationMode === 'RBI_APP') {
			return (
				<>
					<RBISelectors />
					<Button type='link' icon={<DeleteOutlined />} onClick={onDelete} />
				</>
			)
		}
	}

	if (!contextMenuPosition || !(selectedTool === TOOLS.DEFAULT || selectedTool === TOOLS.SELECT)) {
		return
	}

	const style: React.CSSProperties = {
		position: 'absolute',
		top: contextMenuPosition.y - 45,
		left: contextMenuPosition.x,
		boxShadow: '2px 2px 20px 2px rgb(149 149 149)',
		padding: '1px',
		backgroundColor: 'white',
	}

	const DEMOStyle: React.CSSProperties = {
		position: 'absolute',
		top: contextMenuPosition.y + 5,
		left: contextMenuPosition.x,
		backgroundColor: 'white',
	}

	return (
		<ConfigProvider
			theme={{
				components: {
					Button: {
						borderRadius: 2,
						defaultBg: 'white',
						colorBorder: 'white',
						defaultBorderColor: 'transparent',
					},
					Select: {
						borderRadius: 2,
					},
				},
			}}
		>
			<div className='context-menu' style={applicationMode === ApplicationMode.DEMO_APP ? DEMOStyle : style}>
				{applicationMode === ApplicationMode.DEMO_APP ? (
					<DEMOSelectors isEditMode={isEditMode} toggleEditMode={toggleEditMode} onDelete={onDelete} />
				) : isEditMode ? (
					renderSelectors()
				) : (
					<EntityLabel
						entities={entities}
						setEditMode={(): void => {
							setIsEditMode(true)
						}}
					/>
				)}
			</div>
		</ConfigProvider>
	)
}

export default ContextMenu
