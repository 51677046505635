import Header from '@components/Header'
import Sider from '@components/Sider'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import * as styles from './Layout.module.css'
import { useEffect } from 'react'
import { isAuth } from '@utils'

const AppLayout: React.FC = () => {
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		const checkAuth = async (): Promise<void> => {
			const auth = await isAuth()

			if (!auth) {
				navigate('/login', { state: { from: location.pathname } })
			}
		}

		void checkAuth()
	}, [])

	return (
		<div className={styles.mainWrapper}>
			<header className={styles.mainHead}>
				<Header />
			</header>
			<main className={styles.mainContent}>
				<Outlet />
			</main>
			<aside className={styles.mainSide}>
				<Sider />
			</aside>
		</div>
	)
}

export default AppLayout
