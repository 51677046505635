import { ConfigProvider, List, Tree, Typography } from 'antd'
import React, { type FC, useEffect, useState } from 'react'
import type { DiagramComponent } from '../../../../generated/backend'
import { useAppSelector } from '../../../../store'
import type { DemoDiagramComponentMapping, HazopDiagramComponentMapping } from '../../../../store/actions/common/types'
import * as styles from './style.module.css'
import { TableOutlined, PullRequestOutlined } from '@ant-design/icons'
import EmptyData from '../../../EmptyData'
import ListItem from './ListItem'

interface IProps {
	title: string
}

const treeData = [
	{
		title: 'Иерархия 1',
		key: '0-0',
		icon: <TableOutlined />,
		children: [
			{
				title: 'Иерархия 1.1',
				key: '0-0-0',
				icon: <PullRequestOutlined />,
				children: [
					{
						title: 'Иерархия 1.1.1',
						key: '0-0-0-0',
					},
				],
			},
		],
	},
	{
		title: 'Иерархия 2',
		key: '0-1',
		icon: <TableOutlined />,
		children: [
			{
				title: 'Иерархия 2.1',
				key: '0-1-0',
				icon: <PullRequestOutlined />,
				children: [
					{
						title: 'Иерархия 2.1.1',
						key: '0-1-0-0',
					},
				],
			},
		],
	},
	{
		title: 'Иерархия 3',
		key: '0-2',
		icon: <TableOutlined />,
		children: [
			{
				title: 'Иерархия 3.1',
				key: '0-2-0',
				icon: <PullRequestOutlined />,
				children: [
					{
						title: 'Иерархия 3.1.1',
						key: '0-2-0-0',
					},
				],
			},
		],
	},
]

const DEMOEntityList: FC<IProps> = ({ title }) => {
	const diagramComponents = useAppSelector(state => state.draw.stage.diagramComponents)
	const { applicationMode } = useAppSelector(state => state.draw)
	const [dataSource, setDataSource] = useState<DiagramComponent[]>([])

	useEffect(() => {
		if (applicationMode === 'DEMO_APP' && (diagramComponents as DemoDiagramComponentMapping)?.ELEMENT) {
			setDataSource((diagramComponents as DemoDiagramComponentMapping).ELEMENT)
		}
		if (applicationMode === 'HAZOP_APP' && (diagramComponents as HazopDiagramComponentMapping)?.HAZOP_EQUIPMENT) {
			setDataSource((diagramComponents as HazopDiagramComponentMapping).HAZOP_EQUIPMENT)
		}
	}, [applicationMode, diagramComponents])

	return (
		<div style={{ padding: 8 }}>
			<List
				dataSource={dataSource}
				locale={{
					emptyText: <EmptyData />,
				}}
				renderItem={item => <ListItem item={item} />}
			/>
			{/* <div className={styles.asideLeft}>
				<ConfigProvider
					theme={{
						components: {
							Tree: {
								borderRadius: 2,
								nodeSelectedBg: 'rgba(213, 227, 246, 1)',
								colorBorder: 'transparent',
							},
						},
					}}
				>
					<Tree
						treeData={dataSource.map(element => ({ ...element, title: element.name ?? '', key: element.id! }))}
						showIcon={true}
					/>
				</ConfigProvider>
			</div> */}
		</div>
	)
}

export default DEMOEntityList
