import React, { type FC } from 'react'
import { Upload, type UploadProps } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import type { RcFile } from 'antd/es/upload'
import { uploadFile } from '../../utils'
import { useActions } from '@hooks/useActions'

const UploadPdf: FC = () => {
	const { addPdfFile } = useActions()
	const props: UploadProps = {
		name: 'file',
		multiple: true,
		accept: 'application/pdf',
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		customRequest: async info => {
			const { onSuccess, file, onProgress } = info

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const pdfFile = await uploadFile(file as RcFile, onProgress!)

			pdfFile && addPdfFile(pdfFile)
			onSuccess && onSuccess('Ok')
		},
	}

	return (
		<Upload.Dragger {...props}>
			<p className='ant-upload-drag-icon'>
				<InboxOutlined />
			</p>
			<p>Добавить файл</p>
			<p>После загрузки обновите страницу</p>
		</Upload.Dragger>
	)
}

export default UploadPdf
