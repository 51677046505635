import { Avatar } from 'antd'
import React from 'react'
import * as styles from '../style.module.css'

const User = () => {
	return (
		<div className={styles.external}>
			<Avatar style={{ cursor: 'pointer' }}>RBI</Avatar>
		</div>
	)
}

export default User
