import React, { type FC, useEffect, useRef } from 'react'
import { Stage as KonvaStage } from 'react-konva'
import { DrawingLayer, ImageLayer } from '../layers'
import * as style from './style.module.css'
import { useDraw } from './useDraw/useDraw'
import { setContextMenuPosition, setStageAttrs, useAppDispatch, useAppSelector } from '../../store'
import type Konva from 'konva'
import ContextMenu from '../contextMenu/ContextMenu'
import TagDraft from '../shapes/drafts/TagDraft'
import Label from '../label'
import { ApplicationMode } from '../../../consts'

const Stage: FC = () => {
	const stageContainer = useRef<HTMLDivElement>(null)
	const stage = useRef<React.ElementRef<typeof KonvaStage>>(null)
	const { onClick, onMouseUp, onMouseMove, onMouseDown } = useDraw(stage.current)
	const { draft, zoom, position } = useAppSelector(state => state.draw.stage)
	const { imageLayer } = useAppSelector(state => state.draw)

	const applicationMode = useAppSelector(state => state.draw.applicationMode)

	const isDragEnabled = useAppSelector(state => state.draw.stage.isDragEnabled)
	const dispatch = useAppDispatch()

	const onDragEnd = (e: Konva.KonvaEventObject<DragEvent>): void => {
		if (!isDragEnabled) return

		const stage = e.target.getStage()
		const transformer = stage?.findOne('.transformer')
		if (stage && transformer?.getPosition().x) {
			const position = {
				x: stage.container().offsetLeft + transformer.absolutePosition().x,
				y:
					applicationMode === ApplicationMode.DEMO_APP
						? stage.container().offsetTop + transformer.absolutePosition().y + transformer.height()
						: stage.container().offsetTop + transformer.absolutePosition().y,
			}
			dispatch(setContextMenuPosition(position))
		}
	}

	useEffect(() => {
		dispatch(
			setStageAttrs({
				position,
				scale: imageLayer.zoom,
			})
		)
	}, [imageLayer.zoom])

	return (
		<div className={style.stageWrapper} ref={stageContainer}>
			<KonvaStage
				ref={stage}
				width={window.innerWidth}
				height={window.innerHeight}
				scale={zoom}
				x={position.x}
				y={position.y}
				className={style.stage}
				onClick={onClick}
				onMouseDown={onMouseDown}
				onMouseMove={e => {
					onMouseMove(e, draft)
				}}
				onMouseUp={e => {
					onMouseUp(e, draft)
				}}
				onDragEnd={onDragEnd}
				rotation={0}
			>
				<ImageLayer stageContainer={stageContainer.current} />
				<DrawingLayer />
			</KonvaStage>

			<ContextMenu />
			<Label />
			<TagDraft />
		</div>
	)
}

export default Stage
