import { BookOutlined, CheckOutlined, SettingOutlined, TableOutlined } from '@ant-design/icons'
import React from 'react'
import { useOutsideClick } from '@hooks/useOutsideClick'
import { Tooltip } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import * as styles from './Sider.module.css'

interface INavigation {
	label: string
	key: string
	icon?: React.ReactNode
	children?: INavigation[]
	isActive?: boolean
}

const navigation: INavigation[] = [
	{
		label: 'Схемы',
		key: 'schemes',
		icon: <TableOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
		children: [
			{
				label: 'Файлы PDF',
				key: '/pdf-files',
			},
			{
				label: 'Изображения',
				key: '/images',
			},
		],
	},
	{
		label: 'Справочники',
		key: 'dictionaries',
		icon: <BookOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
	},
	{
		label: 'Настройки',
		key: 'settings',
		icon: <SettingOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
		isActive: false,
		children: [
			{
				label: 'Проекты',
				key: '/settings/projects',
			},
		],
	},
]

const Sider: React.FC = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const [navigationMenu, setNavigationMenu] = React.useState<INavigation[]>(navigation)
	const [collapsed, setCollapsed] = React.useState<string>('')

	const closeSubMenu = () => {
		setCollapsed('')
	}

	const subMenuRef = useOutsideClick<HTMLDivElement>(closeSubMenu)

	React.useEffect(() => {
		const changeNavigation = (key: string) => {
			setNavigationMenu(
				navigationMenu.map(itemNav => {
					const findChildren = itemNav.children?.filter(item => item.key === key)

					if (itemNav.key === key || findChildren?.length! > 0) {
						itemNav.isActive = true
						itemNav?.children?.forEach(children => {
							children.isActive = children.key === key
						})
					} else {
						itemNav.isActive = false
						itemNav?.children?.forEach(item => {
							item.isActive = false
						})
					}

					return itemNav
				})
			)
		}

		const path = '/' + window.location.pathname.split('/')[1]

		changeNavigation(path)
	}, [location.pathname])

	return (
		<div className={styles.sider}>
			{navigationMenu.map(itemNav => {
				return (
					<div
						style={{ backgroundColor: itemNav.isActive ? '#38649f' : '' }}
						className={styles.item}
						ref={subMenuRef}
						key={itemNav.key}
						onClick={e => {
							e.stopPropagation()
							if ('children' in itemNav) {
								setCollapsed(collapsed === itemNav.key ? '' : itemNav.key)

								return
							} else {
								closeSubMenu()
							}

							navigate(itemNav.key)
						}}
					>
						{itemNav.icon}
						{itemNav.label.length > 9 ? (
							<Tooltip placement='right' title={itemNav.label}>
								<div className={styles.itemTitle}>{itemNav.label.substring(0, 7) + '...'}</div>
							</Tooltip>
						) : (
							<div className={styles.itemTitle}>{itemNav.label}</div>
						)}
						<div
							className={styles.subMenu}
							style={{
								opacity: collapsed === itemNav.key ? '1' : '',
								visibility: collapsed === itemNav.key ? 'visible' : 'hidden',
								transition: 'all 0.3s ease',
							}}
						>
							{itemNav.children?.map(item => (
								<div
									style={{ backgroundColor: item.isActive ? '#38649f' : '' }}
									key={item.key}
									className={styles.subMenuItem}
									onClick={e => {
										e.stopPropagation()
										navigate(item.key)
										closeSubMenu()
									}}
								>
									{item.label}
									{item.isActive && <CheckOutlined rev='' />}
								</div>
							))}
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default Sider
