import EmptyData from '@components/emptyData/EmptyData'
import { useTypedSelector } from '@hooks/useTypedSelector'
import { ElementDto } from '@lib/src/generated/backend'
import { createElement, deleteElement, fetchElements, updateElement, useAppDispatch } from '@store'
import { Button, Table, TableProps, Tag } from 'antd'
import { useEffect } from 'react'
import EditableCell from './EditableCell'
import EditableRow from './EditableRow'
import { useActions } from '@hooks/useActions'
import { DeleteOutlined } from '@ant-design/icons'

type Props = {}

type ColumnTypes = Exclude<TableProps<ElementDto>['columns'], undefined>
export type InputType = 'input' | 'select' | 'colorPicker'

const components = {
	body: {
		row: EditableRow,
		cell: EditableCell,
	},
}

const defaultColumns: (ColumnTypes[number] & {
	editable?: boolean
	dataIndex: string | string[]
	inputType?: InputType
})[] = [
	{
		title: 'Название',
		dataIndex: 'name',
		key: 'name',
		editable: true,
		inputType: 'input',
	},
	{
		title: 'Цвет',
		dataIndex: 'color',
		key: 'color',
		render: value => (
			<Tag color={value.includes('#') ? value : `#${value}`}>
				{(value.includes('#') ? value : `#${value}`).toUpperCase()}
			</Tag>
		),
		editable: true,
		inputType: 'colorPicker',
		width: '20%',
	},
	{
		title: 'Тип',
		dataIndex: 'type',
		key: 'type',
		render: value => value.name,
		editable: true,
		inputType: 'select',
		width: '20%',
	},
	{
		title: 'Действия',
		dataIndex: 'actions',
		key: 'actions',
		width: '5%',
	},
]

const ElementsTable = (props: Props) => {
	const dispatch = useAppDispatch()
	const elements = useTypedSelector(state => state.elements.list)
	const status = useTypedSelector(state => state.elements.status)

	const { updateLocalElement } = useActions()

	useEffect(() => {
		dispatch(fetchElements())
	}, [])

	const handleCreateElement = async (element: ElementDto) => {
		const { id, type, ...preparedData } = element
		await dispatch(createElement({ ...preparedData, type_id: type?.id ?? type }))
	}
	const handleUpdateElement = async (element: ElementDto) => {
		const { id, type, ...preparedData } = element
		await dispatch(updateElement({ id, data: { ...preparedData, type_id: type?.id ?? type } }))
	}
	const handleDeleteElement = async (id: string) => {
		await dispatch(deleteElement(id))
	}

	const handleSave = async (element: ElementDto) => {
		if (element.name === 'temp' || element.type.id === 'temp') {
			updateLocalElement(element)
			return
		}

		if (element.id === 'temp') {
			await handleCreateElement(element)
		} else {
			await handleUpdateElement(element)
		}
	}

	const columns = defaultColumns.map(col => {
		if (col.key === 'actions') {
			return {
				...col,
				render: (_, record) => (
					<Button type='link' icon={<DeleteOutlined />} onClick={() => handleDeleteElement(record.id!)} />
				),
			} as ColumnTypes[number]
		}
		if (!col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: ElementDto) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				inputType: col.inputType,
				handleSave,
			}),
		}
	})

	return (
		<>
			<Table<ElementDto>
				columns={columns as ColumnTypes}
				rowKey={record => record.id}
				components={components}
				dataSource={elements}
				loading={status === 'loading'}
				locale={{
					emptyText: <EmptyData />,
				}}
			/>
		</>
	)
}

export default ElementsTable
