import ImageTable from '@components/ImageTable/ImageTable'
import * as styles from './ImageFiles.module.css'

const ImageFiles = () => {
	return (
		<div className={styles.container}>
			<ImageTable />
		</div>
	)
}

export default ImageFiles
