import { Table, type TableColumnsType } from 'antd'
import { type FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { imageApi } from '../../lib'
import type { ImageDrawingFileDto } from '../../lib/src/generated/backend'
import EmptyData from '../emptyData/EmptyData'
import { useAppDispatch } from '@store'
import { useTypedSelector } from '@hooks/useTypedSelector'
import { fetchImageList } from '@store/schemes/schemes.actions'

const ImageTable: FC = () => {
	const dispatch = useAppDispatch()
	const imageList = useTypedSelector(state => state.schemes.imageList)
	const status = useTypedSelector(state => state.schemes.status)

	useEffect(() => {
		dispatch(fetchImageList())
	}, [])

	const columns: TableColumnsType<ImageDrawingFileDto> = [
		{
			title: 'Название',
			dataIndex: 'filename',
			key: 'name',
			render: (_, record) => <Link to={`/drawing/${record.id}`}>{record.filename}</Link>,
		},
		{
			title: 'Страница №',
			dataIndex: 'image_number',
			key: 'imageNumber',
		},
		{
			title: 'PDF',
			dataIndex: ['parent_pdf', 'filename'],
			key: 'parentPdfDrawingFile.fileName',
		},
	]

	return (
		<Table
			rowKey={'id'}
			columns={columns}
			loading={status === 'loading'}
			dataSource={imageList}
			locale={{
				emptyText: <EmptyData />,
			}}
		/>
	)
}

export default ImageTable
