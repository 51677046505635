import type { Vector2d } from 'konva/lib/types'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { Label, Text } from 'react-konva'
import { useAppSelector } from '../../../../store'
import type { DemoDiagramComponentMapping } from '../../../../store/actions/common/types'

interface IDemoEntitiesLabel {
	entity: DemoDiagramComponentMapping
	position: Vector2d
	rotate: number
	hovering: boolean
}

const DemoEntitiesLabel: FC<IDemoEntitiesLabel> = ({ entity, position, rotate, hovering }) => {
	const { zoom, invisibleEntitiesId } = useAppSelector(state => state.draw.stage)
	const [offsetY, setOffsetY] = useState(0)
	const [labelText, setLabelText] = useState<string>('')

	const getOffsetY = (): void => {
		if (zoom.y > 1 && zoom.y < 2) {
			setOffsetY(-1 * zoom.y)
			return
		}
		if (zoom.y > 2 && zoom.y < 8) {
			setOffsetY(-1.5 * zoom.y)
			return
		}
		if (zoom.y > 8) {
			return
		}
		setOffsetY(0)
	}

	useEffect(() => {
		getOffsetY()
	}, [zoom])

	useEffect(() => {
		let text = ''
		if (entity?.ELEMENT.length) {
			// @ts-expect-error fix later
			if (!invisibleEntitiesId.includes(entity.ELEMENT[0].id)) {
				text = entity.ELEMENT[0].name ?? ''
			}
		}
		setLabelText(text)
	}, [entity, invisibleEntitiesId])

	const getFontSize = (): number => {
		if (zoom.y > 1) {
			return 14 / (zoom.x * 0.75)
		} else {
			return 14
		}
	}

	return (
		<Label x={position.x} y={position.y} rotation={rotate}>
			<Text
				text={labelText}
				fill={'black'}
				opacity={hovering ? 1 : 0.25}
				fontSize={getFontSize()}
				padding={0}
				offsetY={offsetY}
			/>
		</Label>
	)
}

export default DemoEntitiesLabel
