import { CheckOutlined, CloseOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Dropdown, theme } from 'antd'
import Search from 'antd/es/input/Search'
import React, { type FC, ReactNode, useEffect, useState } from 'react'
import { elementsApi, shapeApi } from '../../../api/api-wrapper'
import { type DiagramComponent, DiagramComponentType, ElementDto } from '../../../generated/backend'
import { getAppData, setContextMenuPosition, useAppDispatch, useAppSelector } from '../../../store'
import type { DemoDiagramComponentMapping } from '../../../store/actions/common/types'
import * as styles from './DEMOSelectors.module.css'

const { useToken } = theme

interface DEMOSelectorsProps {
	isEditMode: boolean
	toggleEditMode: () => void
	onDelete: () => Promise<void>
}

const fetchElementById = async (id: string) => {
	const response = await elementsApi.getElementApiElementsElementIdGet(id)
	return response.data
}

const showTotal = (total: number) => {
	if (total === 0) {
		return 'Ничего не найдено'
	} else if (total === 1) {
		return '1 результат'
	} else if (total > 1 && total < 5) {
		return `${total} результата`
	} else {
		return `${total} результатов`
	}
}

const DEMOSelectors: FC<DEMOSelectorsProps> = ({ isEditMode, toggleEditMode, onDelete }) => {
	const diagramComponents = useAppSelector(state => state.draw.diagramComponents as DemoDiagramComponentMapping)
	const selectedShapes = useAppSelector(state => state.draw.stage.selectedShapes)
	const applicationMode = useAppSelector(state => state.draw.applicationMode)
	const image = useAppSelector(state => state.draw.image)
	const dispatch = useAppDispatch()

	const [open, setOpen] = useState(true)

	const [isLoading, setIsLoading] = useState(false)
	const [elementsList, setElementsList] = useState<ElementDto[]>([])
	const [selectedItem, setSelectedItem] = useState<(ElementDto & { label: ReactNode }) | null>(null)

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen)
	}

	const elementToOption = (element: ElementDto) => ({
		...element,
		label: (
			<div className={styles.resultItem}>
				<div className={styles.title}>{element.name ?? ''}</div>
				<span className={styles.type}>{element.type?.name ?? ''}</span>
			</div>
		),
	})

	useEffect(() => {
		const fetchElements = async () => {
			const response = await elementsApi.getAllElementsApiElementsGet()
			setElementsList(response.data)
		}

		fetchElements()
	}, [])

	useEffect(() => {
		if (selectedShapes.length > 0) {
			const symbols = new Set<DiagramComponent>()
			selectedShapes.forEach(shape => {
				if (shape.entities) {
					const { ELEMENT } = shape.entities as DemoDiagramComponentMapping
					if (ELEMENT.length === 1) {
						symbols.add(ELEMENT[0])
					}
				}
			})

			if (symbols.size === 1) {
				const elementFromApi = symbols.values().next().value as DiagramComponent
				fetchElementById(elementFromApi.id!).then(element => {
					setSelectedItem({
						...element!,
						label: (
							<div className={styles.resultItem}>
								<div className={styles.title}>{element?.name ?? ''}</div>
								<span className={styles.type}>{element?.type?.name ?? ''}</span>
							</div>
						),
					})
				})
			}
		}
	}, [selectedShapes])

	const onSelect = async (element: ElementDto & { label: ReactNode }) => {
		setSelectedItem(element)
		setIsLoading(true)
		await Promise.all(
			selectedShapes.map(shape => {
				return shapeApi.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(
					shape.id,
					element.id!,
					DiagramComponentType.Element
				)
			})
		)
		if (applicationMode && image.id) {
			await dispatch(getAppData())
			toggleEditMode()
		}
		setIsLoading(false)
	}

	const handleDeselect = async (element: ElementDto & { label: ReactNode }) => {
		setSelectedItem(null)
		await Promise.all(
			selectedShapes.map(shape => {
				return shapeApi.unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(
					shape.id,
					element.id!,
					DiagramComponentType.Element
				)
			})
		)
		if (applicationMode && image.id) {
			await dispatch(getAppData())
			toggleEditMode()
		}
	}

	const { token } = useToken()

	const contentStyle: React.CSSProperties = {
		backgroundColor: 'rgba(33, 82, 148, 1)',
		boxShadow: token.boxShadowSecondary,
		width: '300px',
		color: 'rgba(255, 255, 255, 1)',
		padding: '10px 0 12px',
	}

	if (!diagramComponents) {
		return
	}

	return (
		<>
			<ConfigProvider
				wave={{ disabled: true }}
				theme={{
					components: {
						Dropdown: {
							colorBgElevated: 'rgba(33, 82, 148, 1)',
							borderRadiusLG: 2,
							paddingBlock: 0,
							controlPaddingHorizontal: 0,
							paddingXXS: 0,
							marginXXS: 0,
							colorSplit: 'rgba(217, 217, 217, 1)',
							zIndexPopup: 1,
						},
						Input: {
							borderRadius: 2,
							colorBgContainer: 'rgba(33, 82, 148, 1)',
							colorText: 'rgba(255, 255, 255, 0.85)',
							colorTextPlaceholder: 'rgba(88, 122, 169, 1)',
							colorBorder: 'rgba(88, 122, 169, 1)',
							colorPrimaryBgHover: 'rgba(88, 122, 169, 1)',
							fontSize: 14,
							colorPrimaryHover: 'rgba(88, 122, 169, 1)',
							activeShadow: 'none',
							activeBorderColor: 'rgba(88, 122, 169, 1)',
							colorTextDescription: 'rgba(255, 255, 255, 1)',
						},
						Button: {
							borderRadius: 2,
							colorPrimary: 'rgba(33, 82, 148, 1)',
							colorPrimaryHover: 'rgba(33, 82, 148, 1)',
							colorPrimaryActive: 'rgba(33, 82, 148, 1)',
							defaultActiveBg: 'rgba(88, 122, 169, 1)',
							defaultActiveBorderColor: 'rgba(88, 122, 169, 1)',
							defaultBg: 'rgba(33, 82, 148, 1)',
							defaultBorderColor: 'rgba(88, 122, 169, 1)',
							defaultHoverBg: 'rgba(33, 82, 148, 1)',
							defaultHoverBorderColor: 'rgba(88, 122, 169, 1)',
							defaultColor: 'rgba(255, 255, 255, 1)',
						},
					},
				}}
			>
				<Dropdown
					className={styles.dropdown}
					trigger={['click']}
					open={open}
					onOpenChange={handleOpenChange}
					dropdownRender={() => (
						<div style={contentStyle}>
							<div className={styles.dropdownBar}>
								{!isEditMode ? (
									<div className={styles.dropdownTitle}>Привязано</div>
								) : (
									<div className={styles.dropdownTitle}>Привязать к элементу...</div>
								)}
								<Button type='link' style={{ color: 'white' }} icon={<DeleteOutlined />} onClick={onDelete} />
							</div>
							{!isEditMode ? (
								<div className={styles.element} onClick={() => handleDeselect(selectedItem!)}>
									{selectedItem ? selectedItem.label : null}
								</div>
							) : (
								<>
									<div className={styles.searchWrap}>
										<Search placeholder='Поиск...' style={{ width: '100%' }} allowClear />
										<div className={styles.searchCount}>{showTotal(elementsList?.length)}</div>
									</div>
									<div className={styles.scrolledDrop}>
										<ul>
											{(elementsList?.map(elementToOption) || []).map((itemLi, indexLi) => {
												return (
													<li key={indexLi} onClick={() => onSelect(itemLi)}>
														{itemLi.label}
														{isLoading ? (
															<span>{selectedItem && selectedItem.id === itemLi.id ? <LoadingOutlined /> : null}</span>
														) : (
															<span>{selectedItem && selectedItem.id === itemLi.id ? <CheckOutlined /> : null}</span>
														)}
													</li>
												)
											})}
										</ul>
									</div>
								</>
							)}
						</div>
					)}
				>
					<div></div>
				</Dropdown>
			</ConfigProvider>
		</>
	)
}

export default DEMOSelectors
