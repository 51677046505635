import PDFTable from '@components/PDFTable/PDFTable'
import UploadPdf from '@components/upload/UploadPdf'
import { Space } from 'antd'
import * as styles from './PDFFiles.module.css'

const PDFFiles = () => {
	return (
		<Space direction='vertical' style={{ width: '100%' }} className={styles.container}>
			<PDFTable />
			<UploadPdf />
		</Space>
	)
}

export default PDFFiles
