import React, { type FC, useEffect, useState } from 'react'
import { List, Typography } from 'antd'
import { useAppSelector } from '../../../store'
import EmptyData from '../../EmptyData'
import ListItem from './demo/ListItem'
import type { DiagramComponent } from '../../../generated/backend'
import type { RbiDiagramComponentMapping } from '../../../store/actions/common/types'

const RBILists: FC = () => {
	const { RBI_CORROSION_LOOP, RBI_EQUIPMENT, RBI_COMPONENT } =
		useAppSelector(state => state.draw.stage.diagramComponents as RbiDiagramComponentMapping) ?? []

	const selectedShapes = useAppSelector(state => state.draw.stage.selectedShapes)

	const [equipsInLoop, setEquipsInLoop] = useState<DiagramComponent[]>([])
	const [componentsInLoop, setComponentsInLoop] = useState<DiagramComponent[]>([])
	const [corrLoops, setCorrLoops] = useState<DiagramComponent[]>([])

	useEffect(() => {
		if (selectedShapes.length) {
			const equips = new Set<DiagramComponent>()
			const components = new Set<DiagramComponent>()
			const corrLoops = new Set<DiagramComponent>()

			selectedShapes.forEach(shape => {
				const entities = shape.entities as RbiDiagramComponentMapping
				if (entities?.RBI_EQUIPMENT[0]) {
					equips.add(entities.RBI_EQUIPMENT[0])
				}
				if (entities?.RBI_COMPONENT[0]) {
					components.add(entities.RBI_COMPONENT[0])
				}
				if (entities?.RBI_CORROSION_LOOP[0]) {
					corrLoops.add(entities.RBI_CORROSION_LOOP[0])
				}
			})
			setEquipsInLoop(Array.from(equips))
			setComponentsInLoop(Array.from(components))
			setCorrLoops(Array.from(corrLoops))
		} else {
			setEquipsInLoop([])
			setComponentsInLoop([])
			setCorrLoops([])
		}
	}, [selectedShapes])

	return (
		<>
			<div>
				<Typography.Title level={5} style={{ padding: '8px' }}>
					Коррозионные контуры
				</Typography.Title>
				<List
					dataSource={corrLoops.length ? corrLoops : RBI_CORROSION_LOOP ?? []}
					locale={{
						emptyText: <EmptyData />,
					}}
					renderItem={item => (
						<ListItem
							link={`/corrosion-loop/${item.id}`}
							buttonTitle='Перейти на страницу коррозионного контура'
							item={item}
						/>
					)}
				/>
			</div>
			<div>
				<Typography.Title level={5} style={{ padding: '8px' }}>
					Оборудование
				</Typography.Title>
				<List
					dataSource={equipsInLoop.length ? equipsInLoop : RBI_EQUIPMENT ?? []}
					locale={{
						emptyText: <EmptyData />,
					}}
					renderItem={item => (
						<ListItem item={item} link={`/assets/${item.id}`} buttonTitle='Перейти на страницу оборудования' />
					)}
				/>
			</div>
			<div>
				<Typography.Title level={5} style={{ padding: '8px' }}>
					Компоненты
				</Typography.Title>
				<List
					dataSource={componentsInLoop.length ? componentsInLoop : RBI_COMPONENT ?? []}
					locale={{
						emptyText: <EmptyData />,
					}}
					renderItem={item => <ListItem item={item} />}
				/>
			</div>
		</>
	)
}

export default RBILists
