import Layout from '@components/Layout'
import Login from '@components/Login'
import DrawApp from '@lib/index'
import { ApplicationMode } from '@lib/types'
import Dictionaries from '@pages/Dictionaries'
import ImageFiles from '@pages/ImageFiles'
import PDFFiles from '@pages/PDFFiles'
import { type FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const App: FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/drawing/:id' element={<DrawApp applicationMode={ApplicationMode.DEMO_APP} />} />
				<Route path='/login' element={<Login />} />
				<Route path='/' element={<Layout />}>
					<Route path='/pdf-files' element={<PDFFiles />} />
					<Route path='/images' element={<ImageFiles />} />
					<Route path='/dictionaries' element={<Dictionaries />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default App
