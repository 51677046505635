import type { Vector2d } from 'konva/lib/types'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { Label, Text } from 'react-konva'
import { useAppSelector } from '../../../../store'
import type { HazopDiagramComponentMapping } from '../../../../store/actions/common/types'

interface IRbiEntitiesLabel {
	entity: HazopDiagramComponentMapping
	position: Vector2d
	rotate: number
	hovering: boolean
}

const RbiEntitiesLabel: FC<IRbiEntitiesLabel> = ({ entity, position, rotate, hovering }) => {
	const { zoom, invisibleEntitiesId } = useAppSelector(state => state.draw.stage)
	const [offsetY, setOffsetY] = useState(0)
	const [labelText, setLabelText] = useState<string>('')

	const getOffsetY = (): void => {
		if (zoom.y > 1 && zoom.y < 2) {
			setOffsetY(-1 * zoom.y)
			return
		}
		if (zoom.y > 2 && zoom.y < 8) {
			setOffsetY(-1.5 * zoom.y)
			return
		}
		if (zoom.y > 8) {
			return
		}
		setOffsetY(0)
	}

	useEffect(() => {
		getOffsetY()
	}, [zoom])

	useEffect(() => {
		let text = ''

		if (entity?.HAZOP_NODE.length) {
			// @ts-expect-error fix later
			if (!invisibleEntitiesId.includes(entity.HazopNode[0].id)) {
				text = entity.HAZOP_NODE[0].name ?? ''
			}
		}
		if (entity?.HAZOP_EQUIPMENT.length) {
			// @ts-expect-error fix later
			if (!invisibleEntitiesId.includes(entity.HazopEquipment[0].id)) {
				if (text.length > 0) {
					text += ' | '
				}
				text += entity.HAZOP_EQUIPMENT[0].name
			}
		}
		setLabelText(text)
	}, [entity, invisibleEntitiesId])

	const getFontSize = (): number => {
		if (zoom.y > 1) {
			return 14 / (zoom.x * 0.75)
		} else {
			return 14
		}
	}

	return (
		<Label x={position.x} y={position.y} rotation={rotate}>
			<Text
				text={labelText}
				fill={'black'}
				opacity={hovering ? 1 : 0.25}
				fontSize={getFontSize()}
				padding={0}
				offsetY={offsetY}
			/>
		</Label>
	)
}

export default RbiEntitiesLabel
