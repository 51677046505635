import AddElementTypeModal from '@components/AddElementTypeModal/AddElementTypeModal'
import { Button, message } from 'antd'
import * as styles from './Dictionaries.module.css'
import ElementsTable from '@components/ElementsTable/ElementsTable'
import { useActions } from '@hooks/useActions'

const Dictionaries = () => {
	const { addElement } = useActions()

	const [messageApi, contextHolder] = message.useMessage()

	const handleAddEmptyElement = () => {
		addElement({
			id: 'temp',
			name: 'temp',
			color: '#1254f6',
			type: { id: 'temp', name: 'temp' },
		})
	}

	return (
		<div className={styles.container}>
			{contextHolder}
			<div className={styles.header}>
				<Button onClick={handleAddEmptyElement}>Добавить элемент</Button>
				<AddElementTypeModal messageApi={messageApi} />
			</div>
			<div className={styles.table}>
				<ElementsTable />
			</div>
		</div>
	)
}

export default Dictionaries
