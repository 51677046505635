import { ElementDto, ElementTypeDto } from '@lib/src/generated/backend'
import { InputRef, Input, Form, ColorPicker, Select, Button } from 'antd'
import { useState, useRef, useContext, useEffect } from 'react'
import { EditableContext } from './EditableRow'
import * as styles from './ElementsTable.module.css'
import { InputType } from './ElementsTable'
import { elementTypesApi } from '@api/api-wrapper'
import { useActions } from '@hooks/useActions'

interface EditableCellProps {
	title: React.ReactNode
	editable: boolean
	dataIndex: keyof ElementDto
	record: ElementDto
	handleSave: (record: ElementDto) => Promise<void>
	inputType: InputType
}

const fetchElementTypes = async () => {
	try {
		const response = await elementTypesApi.getAllElementTypesApiElementTypesGet()
		return response.data
	} catch (error) {
		console.log(error)
	}
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	inputType,
	...restProps
}) => {
	const form = useContext(EditableContext)!
	const inputRef = useRef<InputRef>(null)

	const [editing, setEditing] = useState(false)
	const [elementTypes, setElementTypes] = useState<ElementTypeDto[]>([])

	const handleSelectFocus = async () => {
		const elementTypes = await fetchElementTypes()
		setElementTypes(elementTypes!)
	}

	useEffect(() => {
		inputType === 'select' && handleSelectFocus()
	}, [])

	useEffect(() => {
		if (editing) {
			inputType === 'input' && inputRef.current?.focus()
		}
	}, [editing])

	const toggleEdit = () => {
		setEditing(!editing)
		if (inputType === 'select') {
			form.setFieldsValue({ [dataIndex]: (record[dataIndex] as ElementTypeDto)?.id })
			return
		}
		form.setFieldsValue({ [dataIndex]: record[dataIndex] })
	}

	const renderFormItem = (type: InputType) => {
		switch (type) {
			case 'input':
				return (
					<Form.Item noStyle name={dataIndex} required>
						<Input ref={inputRef} onPressEnter={save} onBlur={save} />
					</Form.Item>
				)
			case 'colorPicker':
				return (
					<Form.Item noStyle name={dataIndex} normalize={value => value.toHex()} required>
						<ColorPicker
							panelRender={panel => (
								<>
									{panel}{' '}
									<Button style={{ marginTop: '10px' }} size='small' onClick={save} type='primary'>
										Сохранить
									</Button>
								</>
							)}
							disabledAlpha
							defaultFormat='hex'
							showText
						/>
					</Form.Item>
				)
			case 'select': {
				return (
					<Form.Item
						noStyle
						name={dataIndex}
						normalize={value => elementTypes.find(item => item.id === value)}
						required
					>
						<Select
							onBlur={save}
							onSelect={save}
							onFocus={handleSelectFocus}
							style={{ width: '100%' }}
							options={elementTypes.map(item => ({ label: item.name, value: item.id }))}
						/>
					</Form.Item>
				)
			}
		}
	}

	const save = async () => {
		try {
			const values = await form.validateFields()
			
			await handleSave({ ...record, ...values })

			toggleEdit()
		} catch (errInfo) {
			console.log('Save failed:', errInfo)
		}
	}

	let childNode = children

	if (editable) {
		childNode = editing ? (
			renderFormItem(inputType)
		) : (
			<div className={styles['editable-cell-value-wrap']} style={{ paddingInlineEnd: 24 }} onClick={toggleEdit}>
				{children}
			</div>
		)
	}

	return <td {...restProps}>{childNode}</td>
}

export default EditableCell
